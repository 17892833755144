import { Directive, ElementRef, Input } from '@angular/core';
import {UserFacade} from "@app/core/facades/user.facade";
import {UserPermissionCodeEnum} from "@app/core/enums/user-permission-code.enum";

@Directive({
  selector: '[canDoAction]',
  standalone: true
})
export class CanDoActionDirective {

  @Input('canDoAction')
  canDoAction!: UserPermissionCodeEnum|UserPermissionCodeEnum[];

  constructor(
    private _el: ElementRef,
    private _userFacade: UserFacade
  ) { }

  ngOnInit() {
    // Check if user can do action
    if (!this.canDoAction) { return; }
    if (
      (Array.isArray(this.canDoAction) && !this._userFacade.canUserDoAction(this.canDoAction)) ||
      (!Array.isArray(this.canDoAction) && !this._userFacade.canUserDoAction([this.canDoAction]))
    ) {
      // Remove element from DOM
      this._el.nativeElement.remove();
    }
  }
}
