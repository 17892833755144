export enum UserPermissionCodeEnum {

  //EVALUATIONS
  VIEW_EVALUATION_LIST = 'VIEW_EVALUATION_LIST',
  CLONE_EVALUATION = 'CLONE_EVALUATION',
  CREATE_EVALUATION = 'CREATE_EVALUATION',
  EDIT_EVALUATION = 'EDIT_EVALUATION',
  DELETE_EVALUATION = 'DELETE_EVALUATION',
  ACTIVATE_EVALUATION = 'ACTIVATE_EVALUATION',
  DEACTIVATE_EVALUATION = 'DEACTIVATE_EVALUATION',
  TERMINATE_EVALUATION = 'TERMINATE_EVALUATION',

  //EVALUATION RESULTS
  VIEW_EVALUATION_RESULT = 'VIEW_EVALUATION_RESULT',
  VIEW_EVALUATION_RESULT_LIST = 'VIEW_EVALUATION_RESULT_LIST',

  //SCHEDULE FLIGHTS
  VIEW_SCHEDULE_FLIGHT_LIST = 'VIEW_SCHEDULE_FLIGHT_LIST',
  VIEW_SCHEDULE_FLIGHT = 'VIEW_SCHEDULE_FLIGHT',
  CREATE_SCHEDULE_FLIGHT = 'CREATE_SCHEDULE_FLIGHT',
  EDIT_SCHEDULE_FLIGHT = 'EDIT_SCHEDULE_FLIGHT',
  DELETE_SCHEDULE_FLIGHT = 'DELETE_SCHEDULE_FLIGHT',

  //KPIS
  VIEW_KPI_LIST = 'VIEW_KPI_LIST',
  CREATE_KPI = 'CREATE_KPI',
  EDIT_KPI = 'EDIT_KPI',
  DELETE_KPI = 'DELETE_KPI',
  DEACTIVATE_KPI = 'DEACTIVATE_KPI',

  //ML MODELS
  VIEW_ML_MODEL_LIST = 'VIEW_ML_MODEL_LIST',
  CREATE_ML_MODEL = 'CREATE_ML_MODEL',
  EDIT_ML_MODEL = 'EDIT_ML_MODEL',
  DELETE_ML_MODEL = 'DELETE_ML_MODEL',
  DEACTIVATE_ML_MODEL = 'DEACTIVATE_ML_MODEL',

  //OPTIMISATIONS
  VIEW_OPTIMISATION_LIST = 'VIEW_OPTIMISATION_LIST',
  CREATE_OPTIMISATION = 'CREATE_OPTIMISATION',
  EDIT_OPTIMISATION = 'EDIT_OPTIMISATION',
  DELETE_OPTIMISATION = 'DELETE_OPTIMISATION',
  DEACTIVATE_OPTIMISATION = 'DEACTIVATE_OPTIMISATION',
  RUN_OPTIMISATION = 'RUN_OPTIMISATION',
  TERMINATE_OPTIMISATION = 'TERMINATE_OPTIMISATION',

  //OPTIMISATION RESULTS
  VIEW_OPTIMISATION_RESULT = 'VIEW_OPTIMISATION_RESULT',

  //PROFILES
  VIEW_PROFILE_LIST = 'VIEW_PROFILE_LIST',
  CREATE_PROFILE = 'CREATE_PROFILE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  DELETE_PROFILE = 'DELETE_PROFILE',
  DEACTIVATE_PROFILE = 'DEACTIVATE_PROFILE',
  VIEW_PROFILE = 'VIEW_PROFILE',

  //USERS
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  VIEW_USER = 'VIEW_USER',
  DELETE_USER = 'DELETE_USER',
  UNLOCK_USER = 'DELETE_USER',

  //WISHLISTS
  VIEW_WISHLIST_LIST = 'VIEW_WISHLIST_LIST',
  CREATE_WISHLIST = 'CREATE_WISHLIST',
  EDIT_WISHLIST = 'EDIT_WISHLIST',
  DELETE_WISHLIST = 'DELETE_WISHLIST',
  DEACTIVATE_WISHLIST = 'DEACTIVATE_WISHLIST',

  //FLIGHT DEMANDS
  VIEW_FLIGHT_DEMAND_LIST = 'VIEW_FLIGHT_DEMAND_LIST',
  CREATE_FLIGHT_DEMAND = 'CREATE_FLIGHT_DEMAND',
  EDIT_FLIGHT_DEMAND = 'EDIT_FLIGHT_DEMAND',
  DELETE_FLIGHT_DEMAND = 'DELETE_FLIGHT_DEMAND',
  ACTIVATE_FLIGHT_DEMAND = 'ACTIVATE_FLIGHT_DEMAND',
  DEACTIVATE_FLIGHT_DEMAND = 'DEACTIVATE_FLIGHT_DEMAND'
}
